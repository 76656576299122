<template>
  <div class="space-y-4"></div>
</template>

<script>
export default {
  name: 'Error',
}
</script>

<style>

</style>